<template>
  <div class="UpdateLog">
    <TopBar title="更新日志">
      <BackIcon></BackIcon>
    </TopBar>
    <TopInfo :title="title" :img="imgPath"></TopInfo>
    <v-container class="px-5 pt-0">
      
      <v-card elevation="0">
        <v-card-text style="height: 80%" class="py-0">
          <div class="mt-6" v-for="(ver, verIdx) in versions" :key="verIdx">
            <h2 :class="verIdx === 0 ? 'primary--text' : undefined">
              v{{ ver.version }}
            </h2>
            <div
              class="pl-4 pt-2"
              v-for="(note, noteIdx) in ver.notes"
              :key="noteIdx"
            >
              <strong>{{ note.name }}</strong>
              <ul>
                <li v-for="(desc, descIdx) in note.descs" :key="descIdx">
                  {{ desc }}
                </li>
              </ul>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { isLargeScreenMixin, setChildViewMixin } from "common/mixin"
import { getRelease } from 'network/about'
import code from 'assets/img/code.svg'
import codeDark from 'assets/img/codeDark.svg'

export default {
  name: "UpdateLog",
  mixins: [isLargeScreenMixin, setChildViewMixin],
  components: {},
  data() {
    return {
      title: "更新日志",
      // versions: []
      versions: [
        {
          version: "0.1.2",
          notes: [
            {
              name: "完成账户修改信息功能",
              descs: ["用户可在我的页面中点击头像修改信息", "修改头像功能暂时不可用"]
            }
          ]
        },
        {
          version: "0.1.1",
          notes: [
            {
              name: "完成账户注销功能",
              descs: ["用户可在我的页面中点击注销账户", "输入确认注销系统将会删除所有者信息"]
            }
          ]
        },
        {
          version: "0.1.0",
          notes: [
            {
              name: "完成自动部署",
            }
          ]
        },
        {
          version: "0.0.9",
          notes: [
            {
              name: "完成功能信息绑定",
              descs: ["用户能够自主选择开学日期","绑定开学日期后会从智慧校园获取课表","今日页面下拉可以刷新课表及用户信息"]
            }
          ],
        },
        {
          version: "0.0.8",
          notes: [
            {
              name: "完成卡片设置",
              descs: ["用户可在`我的->卡片设置`，选择主页卡片的显示"]
            }
          ],
        },
        {
          version: "0.0.7",
          notes: [
            {
              name: "完成通知卡片",
            }
          ],
        },
        {
          version: "0.0.6",
          notes: [
            {
              name: "初步完成日历卡片和课程表的渲染",
              descs: ["用户未绑定时只会提示:今日没有课程安排","用户进入今日视图则会获取课程信息"]
            }
          ],
        },
        {
          version: "0.0.5",
          notes: [
            {
              name: "更新清除数据说明",
            }
          ],
        },
        {
          version: "0.0.4",
          notes: [
            {
              name: "完成绑定卡片",
              descs: [
                "2020.12.10 用户首次进入主页即可看到绑定卡片并绑定学号和密码",
              ],
            },
            {
              name: "完成隐私协议",
              descs: [
                "2020.12.10 绑定卡片内的隐私协议及声明"
              ]
            }
          ],
        },
        {
          version: "0.0.3",
          notes: [
            {
              name: "完成登录注册页面设计",
              descs: [
                "2020.12.07 采用单一页面设计完成",
              ],
            },
            {
              name: "完成登录注册的点击事件合法性",
              descs: [
                "2020.12.07 输入框的值使用正则匹配判定是否满足条件"
              ]
            },
            {
              name: "完成页面内的鉴权",
              descs: [
                "2020.12.07 使用Vue-router的全局导航守卫来进行拦截"
              ]
            }
          ],
        },
        {
          version: "0.0.2",
          notes: [
            {
              name: "完成我的页面",
              descs: [
                "2020.12.02 包括设置卡片、个人信息卡片、授权卡片",
              ],
            },
          ],
        },
        {
          version: "0.0.1",
          notes: [
            {
              name: "确立项目",
              descs: [
                "2020.11.28 确定启动Na+课表项目",
              ],
            },
          ],
        },
      ],
    };
  },
  created() {
    // getRelease().then(res => {
    //   console.log(res)
    //   this.versions = res.releases
    // })
  },
  computed: {
    imgPath() {
      return this.isDark ? codeDark : code
    }
  }
};
</script>

<style scoped>
</style>